





























import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { YmsModule } from '@/store/index';

import { InventoryHeaders } from './models/InventoryHeaders';

import InventoryAction from '@/pages/YMS/components/InventoryAction.vue';
import { YmsType } from '../models/yms.model';
import YMSFilters from '@/pages/YMS/components/YMSFilters.vue';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable, InventoryAction, YMSFilters }
})
export default class Inventory extends Vue {
  YmsModule = YmsModule;
  YmsType = YmsType;

  columns: TableHeaders[] = InventoryHeaders;
  inventory: any = {};

  get rows() {
    const a = [];
    for (let index = 0; index < 100; index++) {
      a.push({
        category: `caategory${index}`,
        date: '20210428000000',
        orderNumber: `aaaa${index}`,
        billTo: `bill to ${index}`,
        containerNumber: `number124 ${index}`
      });
    }
    return a;
  }

  get isLastPage(): boolean {
    return !this.YmsModule.meta.lastKey;
  }

  constructor() {
    super();
  }

  get inventoryList() {
    return this.YmsModule.ymsData;
  }

  openModal(rowData) {
    YmsModule.setYmsType(YmsType.INVENTORY);
    YmsModule.setInventoryId(rowData.data.id);
    this.$bvModal.show('inventory-action-modal');
  }
}
