import {
  TableCategory,
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const InventoryHeaders: TableHeaders[] = [
  {
    key: 'date',
    label: `Date`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'orderNumber',
    label: `WO #`,
    sortable: true
  },
  {
    key: 'type',
    label: `Type`,
    sortable: true
  },
  {
    key: 'time',
    label: `Time`,
    sortable: true
  },
  {
    key: 'division',
    label: `DIV`,
    sortable: true
  },
  {
    key: 'yard',
    label: `Yard`,
    sortable: true
  },
  {
    key: 'locationName',
    label: `LOCA`,
    sortable: true
  },
  {
    key: 'truck',
    label: `TRUCK #`,
    sortable: true
  },
  {
    key: 'truckCompany',
    label: `Company`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'containerSize',
    label: `SIZE`,
    sortable: true
  },
  {
    key: 'shippingLine',
    label: `SSL`,
    sortable: true
  },
  {
    key: 'seal',
    label: `SEAL #`,
    sortable: true
  },
  {
    key: 'chassisNumber',
    label: `CHASSIS #`,
    sortable: true
  },
  {
    key: 'chassisPool',
    label: `POOL`,
    sortable: true
  },
  {
    key: 'updatedBy',
    label: `UPDATED By`,
    sortable: true
  }
];
